<template>
  <div class="group-retrieval">
    <div class="group-head">
      <el-form ref="form" :inline="true">
        <el-form-item>
          <el-input
            clearable
            size="small"
            v-model="searchForm.channelId"
            placeholder="群号"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-input
            clearable
            size="small"
            v-model="searchForm.channelUsername"
            placeholder="群用户名"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-input
            clearable
            size="small"
            v-model="searchForm.channelTitle"
            placeholder="群名"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-input
            clearable
            size="small"
            v-model="searchForm.channelSummary"
            placeholder="群简介"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-select
            v-model="searchForm.tips"
            placeholder="状态"
            size="small"
            clearable
          >
            <el-option label="未采集" :value="0"></el-option>
            <el-option label="已采集" :value="1"></el-option>
          </el-select>
        </el-form-item>

        <el-form-item>
          <el-button
            type="primary"
            class="el-icon-search"
            size="small"
            @click="searchFun"
            :loading="loading"
          >
            查询
          </el-button>
          <el-button
            :loading="loading"
            class="el-icon-refresh"
            size="small"
            @click="refresh"
          >
            刷新
          </el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="group-content" v-loading="loading">
      <el-table
        style="margin-bottom:10px"
        :row-style="{ height: 0 + 'px' }"
        :cell-style="{ padding: '5px 0' }"
        header-row-class-name="tableheader"
        :data="tableData"
        border
        :height="windowsHeight - 220"
      >
        <el-table-column prop="date" label="头像" width="60" align="center">
          <template slot-scope="scope">
            <img
              class="table-photo"
              :src="
                scope.row.channelPhoto
                  ? 'data:image/png;base64,' + scope.row.channelPhoto
                  : require('@/assets/imgs/p1.png')
              "
              alt=""
            />
          </template>
        </el-table-column>
        <el-table-column
          prop="channelId"
          label="群号"
          width="140"
        ></el-table-column>
        <el-table-column
          prop="channelUsername"
          label="群用户名"
          width="200"
        ></el-table-column>
        <el-table-column prop="channelTitle" label="群名称"></el-table-column>
        <el-table-column label="群简介" prop="channelSummary"></el-table-column>
        <el-table-column
          prop="tips"
          label="采集状态"
          width="100"
          class="nolink"
        >
          <template slot-scope="scope">
            <el-tag
              :type="scope.row.tips === 0 ? 'warning' : 'success'"
              disable-transitions
            >
              {{ scope.row.tips === 0 ? '未采集' : '已采集' }}
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="160">
          <template slot-scope="scope">
            <el-button
              type="text"
              style="color:#046DBB"
              @click="retrieval(scope.row)"
            >
              开始检索
            </el-button>
            <el-button
              v-if="scope.row.tips == 0"
              type="text"
              style="color:#2EB31D"
              @click="collection(scope.row.id, 1)"
            >
              开始采集
            </el-button>
            <el-button
              v-else
              type="text"
              style="color:#f50202d9"
              @click="collection(scope.row.id, 0)"
            >
              暂停采集
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <pagination
        :total="total"
        :page-size="limit"
        :currentPage="current"
        :pagerCount="5"
        :background="false"
        :sizesArr="[20, 50, 100]"
        @currentChange="currentChange"
        @sizeChange="sizeChange"
      />
    </div>
  </div>
</template>

<script>
import { telegramChannels, collection } from '@/api/retrieval'
export default {
  name: 'SearchPosts',
  data() {
    return {
      searchForm: {
        channelId: '',
        channelUsername: '',
        channelSummary: '',
        channelTitle: '',
        tips: ''
      },
      loading: false,
      total: 0,
      current: 1,
      limit: 20,
      tableData: []
    }
  },
  computed: {
    windowsHeight() {
      return this.$store.state.windowData.height
    }
  },
  created() {
    this.getData()
  },
  methods: {
    // 检索
    retrieval(row) {
      this.$router.push('/group/detail/' + row.channelId)
    },
    searchFun() {
      this.getData()
    },
    currentChange(val) {
      this.current = val
      this.getData()
    },

    sizeChange(val) {
      this.current = 1
      this.limit = val
      this.getData()
    },
    getData() {
      this.loading = true
      telegramChannels(this.current, this.limit, this.searchForm).then(data => {
        this.total = data.all_count
        this.tableData = data.items
        this.loading = false
      })
    },
    refresh() {
      this.searchForm.channelId = ''
      this.searchForm.channelUsername = ''
      this.searchForm.channelSummary = ''
      this.searchForm.channelTitle = ''
      this.searchForm.tips = ''
      this.current = 1
      this.getData()
    },
    collection(id, type) {
      this.$confirm(
        `${type == 1 ? '开始采集' : '暂停采集'}， 是否继续?`,
        '提示',
        {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }
      )
        .then(() => {
          collection(id, type).then(data => {
            this.$message({
              type: 'success',
              message: type == 1 ? '开始采集' : '暂停采集'
            })
            this.getData()
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消'
          })
        })
    }
  }
}
</script>

<style lang="scss" scoped>
.nolink {
}
.group-retrieval {
  width: 100%;
  height: calc(100vh - 79px);
  overflow: hidden;
  background: rgb(240, 242, 245);
  padding: 10px;
  .group-head {
    background: white;
    padding: 5px;
    white-space: nowrap;
    ::v-deep .el-form-item {
      margin-bottom: 0;
    }
    overflow: hidden;
    border: 1px solid #e5e5e5;
    box-shadow: 0 1px 3px 0 #d8d9d9;
    border-radius: 5px;
  }
  .group-content {
    background: white;
    margin-top: 5px;
    background: white;
    padding: 10px;
    width: 100%;
    height: calc(100% - 55px);
    overflow: hidden;
    border: 1px solid #e5e5e5;
    box-shadow: 0 1px 3px 0 #d8d9d9;
    border-radius: 5px;
    .table-photo {
      width: 29px;
      height: 29px;
      border-radius: 5px;
    }
  }
  ::v-deep .tableheader th {
    background: rgb(243, 247, 249);
    padding: 8px 0;
  }
}
.el-link.el-link--danger.is-underline:hover:after,
.el-link.el-link--danger:after {
  border: 0;
}
.el-link.el-link--danger {
  cursor: default;
}
</style>
